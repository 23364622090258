import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import Firebase from "firebase/compat/app";

import { auth } from "../firebase";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthProviderValue {
  currentUser: Firebase.User | null;
  logIn: (details: LogInDetails) => Promise<Firebase.auth.UserCredential>;
  logOut: () => Promise<void>;
  signUp: (details: LogInDetails) => Promise<Firebase.auth.UserCredential>;
}

interface LogInDetails {
  email: string;
  password: string;
}

const signUp = ({ email, password }: LogInDetails) =>
  auth.createUserWithEmailAndPassword(email, password);

const logIn = ({ email, password }: LogInDetails) =>
  auth.signInWithEmailAndPassword(email, password);

const logOut = () => auth.signOut();

const AuthContext = createContext<AuthProviderValue>({
  currentUser: null,
  logIn,
  logOut,
  signUp,
});

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<Firebase.User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: AuthProviderValue = { currentUser, logIn, logOut, signUp };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
