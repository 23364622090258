import { FormEvent, ReactElement, useRef, useState } from "react";
import { Alert, Button, Card, Container, Form } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function LogIn(): ReactElement {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);

  const { currentUser, logIn } = useAuth();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formEmail = emailRef.current!.value;
    const formPassword = passwordRef.current!.value;

    try {
      setError("");
      setLoading(true);
      await logIn({ email: formEmail, password: formPassword });
    } catch {
      setError("Failed to log in. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "90vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center m-4" style={{ fontWeight: "800" }}>
              Booker
            </h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {currentUser && <Navigate to="/" replace={true} />}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" className="mt-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  id="bk-email"
                  required
                />
              </Form.Group>
              <Form.Group id="password" className="mt-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  id="bk-password"
                  required
                />
              </Form.Group>
              <Form.Group id="first-name" className="mt-4">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  ref={firstNameRef}
                  id="bk-first-name"
                  required
                />
              </Form.Group>
              <Form.Group id="surname" className="mt-4">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  type="text"
                  ref={firstNameRef}
                  id="bk-surname"
                  required
                />
              </Form.Group>
              <Button
                variant="dark"
                disabled={loading}
                className="mt-4 w-100"
                type="submit"
              >
                Log In
              </Button>
            </Form>
            <div className="w-100 text-center mt-2">
              Don't have an account? <Link to="/sign-up">Register</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
