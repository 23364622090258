import { useAuth } from "../../contexts/AuthContext";
import { Header } from "../Header";

export function Dashboard() {
  const { currentUser } = useAuth();

  return (
    <>
      <Header />
      <div className="d-flex justify-content-center mt-5">
        <small>{!!currentUser && currentUser.email}</small>
      </div>
    </>
  );
}
