import { FormEvent, ReactElement, useRef, useState } from "react";
import { Alert, Button, Card, Container, Form } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function SignUp(): ReactElement {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);

  const { currentUser, signUp } = useAuth();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formEmail = emailRef.current!.value;
    const formPassword = passwordRef.current!.value;
    const formConfirmPassword = passwordConfirmRef.current!.value;

    if (formPassword !== formConfirmPassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signUp({ email: formEmail, password: formPassword });
    } catch {
      setError("Failed to create an account. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "90vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center m-4" style={{ fontWeight: "800" }}>
              Booker
            </h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {currentUser && <Navigate to="/" replace={true} />}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" className="mt-4">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password" className="mt-4">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirm" className="mt-4">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                />
              </Form.Group>
              <Button
                variant="dark"
                disabled={loading}
                className="mt-4 w-100"
                type="submit"
              >
                Sign Up
              </Button>
            </Form>
            <div className="w-100 text-center mt-2">
              Already have an account? <Link to="/log-in">Log In</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
