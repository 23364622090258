import { ReactElement } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import styles from "./App.module.css";
import { Dashboard } from "./components/Dashboard";
import { LogIn } from "./components/LogIn";
import { SignUp } from "./components/SignUp";

export function App(): ReactElement {
  return (
    <main className={styles.root}>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/log-in" element={<LogIn />} />
        </Routes>
      </Router>
    </main>
  );
}
