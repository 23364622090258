import { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import styles from "./Header.module.css";

export function Header(): ReactElement {
  const { currentUser, logOut } = useAuth();

  const handleLogOut = async () => {
    await logOut();

    return <Navigate to="/log-in" replace={true} />;
  };

  return (
    <header className={styles.root}>
      <p className={styles.appTitle} data-testid="app-title">
        Booker
      </p>
      {!!currentUser ? (
        <Button variant="link" onClick={handleLogOut}>
          Log Out
        </Button>
      ) : (
        <Navigate to="/log-in" replace={true} />
      )}
    </header>
  );
}
